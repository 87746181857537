import React, { useState, useEffect } from "react";

import Badge from "../../../images/Strategy/Badge.jpg";
import { makeStripePayment } from "../../../services/StoreService";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentFail from "./PaymentFail";
import { Row, Col, Card, Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SubscriptionPlans from "./SubscriptionPlans";
import {
  getCurrentSubscription,
  getInvoice,
  getSubscriptionPlan,
  getTransactionHistory,
  makeSubscriptionPlanPayment,
} from "../../../services/StripePayment";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ProductImageSlider from "../../layouts/StoreFront/ProductImageSlider";
import user1 from "../../../images/Strategy/t8.webp";
import user2 from "../../../images/Strategy/t2.png";
import user3 from "../../../images/Strategy/t3.webp";
import user4 from "../../../images/Strategy/t6.png";
import user5 from "../../../images/Strategy/v5.png";
import user6 from "../../../images/Strategy/t4.webp";

const Vid = ({ id, src, play }) => {
  const vidRef = React.createRef();
  useEffect(() => {
    if (play) {
      vidRef.current?.play();
      //   const active = vidRef.current?.closest(".slick-active");

      //   if (!active) {
      //     vidRef.current?.pause();
    }
    // } else {
    //   vidRef.current?.pause();
    // }
  }, [play]);

  return (
    <div style={{ height: "100%", borderRadius: "15px" }}>
      <video
        className={`video-${id}`}
        ref={vidRef}
        width="100%"
        controls
        style={{ height: "100%" }}
      >
        <source src={src} type="video/mp4" id={id} />
      </video>
    </div>
  );
};

const StripePaymentForm = ({ comment }) => {
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [Show, setShow] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [selectedStore, setSelectedStore] = useState(
    JSON.parse(localStorage.getItem("store"))?.SelectedStrategy ||
      JSON.parse(localStorage.getItem("store"))?.SubscriptionPlan
  );

  const [selectedStrategy, setselectedProduct] = useState(
    localStorage.getItem("store") !== undefined
      ? JSON.parse(localStorage.getItem("store"))?.SelectedStrategy ||
          JSON.parse(localStorage.getItem("store"))?.SubscriptionPlan
      : ""
  );
  const [selectedUsers, setSelectedUser] = useState("1");
  const [selectedPlan, setSelectedPlan] = useState();
  const [allPlan, setAllPlan] = useState(true);
  const [viewPlan, setViewPlan] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [inputField, setinputField] = useState(false);
  const [Index, setIndex] = useState(0);
  const [FinalPlan, setFinalPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [strategyStatus, setStrategyStatus] = useState(
    localStorage.getItem("strategyStatus") || "false"
  );
  // const [productImage, setproductImage] = useState([{}]);
  const [partnersData, setPartnersData] = useState(
    JSON.parse(localStorage.getItem("store"))?.SelectedStrategy.videos
  );

  const [curIdx, setCurIdx] = useState(0);
  const [Idx, setIdx] = useState(0);
  const [SelectedImage, setSelectedImage] = useState(
    JSON.parse(localStorage.getItem("store"))?.SelectedStrategy?.image
  );
  let settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 3841,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const store = JSON.parse(localStorage.getItem("store"))?.SelectedStrategy;

    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get("status");
    sessionStorage.setItem("strategyImported", false);
    if (paramValue === "success") {
      setShow(true);
      navigate("/payment-success");
      setTimeout(() => {
        sessionStorage.removeItem("strategyImported");
      }, 2000);
    } else if (paramValue == "fail") {
      navigate("/payment-fail", { state: { Subscription: true } });
      setMessage("Payment Failed !");
      setPaymentFailed(true);
      sessionStorage.removeItem("strategyImported");
    } else {
      navigate("/stripe");
    }
    getSubscriptionPlanDetails();
  }, []);

  const getNameAndTypes = (file) => {
    if (typeof file !== "string") {
      // Handle the case where file is not a string, return an appropriate value
      return { name: "", type: "" };
    }

    const match = file.match(/\/([^\/?#]+)[^\/]*$/);
    const filename = match ? match[1] : "";

    if (typeof filename !== "string") {
      // Handle the case where filename is not a string, return an appropriate value
      return { name: "", type: "" };
    }

    const nameWithoutExtension = filename.split(".")[0];
    const type = filename.includes(".") ? `.${filename.split(".").pop()}` : "";

    return { name: nameWithoutExtension, type: type };
  };

  useEffect(() => {
    setTimeout(() => {
      setMessage();
      setShow(false);
    }, 6000);
  }, [Show]);

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage();
    }, 6000);
  }, [errorMessage]);

  const handlePaymentSubmit = (event) => {
    event.preventDefault();
    localStorage.removeItem("session_id");
    setLoading(true);
    const data = makeStripePayment(
      FinalPlan,
      "usd",
      selectedUsers,
      selectedStrategy,
      localStorage.getItem("organization_id"),
      userInfoDetails.data.accessToken
    );
    data?.then((res) => {
      setLoading(false);
      localStorage.setItem("session_id", res?.data?.data?.session_id);
      sessionStorage.setItem("strategyImported", "true");
      if (res.data.message.includes("added successfully") === true) {
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h3
                  style={{
                    // color: "#ffffff",
                    fontSize: "18px",
                  }}
                >
                  Strategy added successfully
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "strategy add",
            className: "toast-success-container toast-success-container-after ",
          }
        );
        setTimeout(() => {
          navigate("/home");
        }, 3000);
      } else {
        window.location.replace(res?.data?.data?.url);
      }
    });
    data?.catch((err) => {
      setErrorMessage(err?.response?.data?.message);
      if (
        err?.response?.data?.message ===
        "Only the organization owner can make payment"
      ) {
        setErrorMessage(err?.response?.data?.message);
      }
    });
  };

  const getSubscriptionPlanDetails = async () => {
    const data = getSubscriptionPlan(userInfoDetails?.data?.accessToken);
    data?.then(
      await ((res) => {
        setSubscriptionPlans(res?.data?.data);
        setFinalPlan(
          res?.data?.data[0]?.plan_name == "Yearly plan" ? "year" : "month"
        );
      })
    );
    data?.catch(
      await ((err) => {
        console.log("error deleteTask");
      })
    );
  };

  const onValueChange = (event, index, plan) => {
    setSelectedUser(event.target.value);
    setIndex(index);
    setFinalPlan(plan);
    // setError(false);
  };

  const discardHandler = () => {
    setFinalPlan("");
    setSelectedUser(null);
  };
  return (
    <>
      {Show && message ? (
        <ToastContainer
          position="top-right"
          autoClose={false}
          closeOnClick={true}
          style={{ top: "16%", right: "2%", width: "30%", minHeight: "35%" }}
          toastStyle={{ backgroundColor: "#09BD3C", color: "#ffffff" }}
          closeButton={false}
        >
          {toast(
            <>
              <button
                className="float-end p-1 border-0 ml-5 mt-0"
                style={{
                  backgroundColor: "#09BD3C",
                  color: "#ffffff",
                  fontSize: "20px",
                }}
              >
                X
              </button>
              <h3 style={{ color: "#ffffff" }}>
                <span style={{ color: "red" }}> </span>
                {message}
              </h3>
            </>,
            {
              toastId: "success1",
            }
          )}
        </ToastContainer>
      ) : (
        ""
      )}

      {errorMessage?.length >= 1 ? (
        <ToastContainer
          position="top-right"
          autoClose={false}
          closeOnClick={true}
          style={{ top: "16%", right: "2%", width: "40%", minHeight: "35%" }}
          toastStyle={{ backgroundColor: "#e24822", color: "#ffffff" }}
          closeButton={false}
        >
          {toast.error(
            <>
              {/* <button
                className="float-end p-1 border-0 ml-5 mt-0"
                style={{
                  backgroundColor: "#e24822",
                  color: "#ffffff",
                  fontSize: "20px",
                }}
              >
                X
              </button> */}
              <h3 style={{ color: "#ffffff" }}>
                <span style={{ color: "white" }}> </span>
                {errorMessage}
              </h3>
            </>,
            {
              toastId: "success2",
            }
          )}
        </ToastContainer>
      ) : (
        ""
      )}

      <div>
        {selectedStore && (
          <>
            {/* <form onSubmit={handlePaymentSubmit}> */}
            <div className="row bg-white p-4">
              {loading && (
                <div
                  style={{ position: "relative", top: "50%" }}
                  className="m-0 p-0 text-center"
                >
                  <center>
                    <div className="loading-dots--dot mx-1"></div>
                    <div className="loading-dots--dot "></div>
                    <div className="loading-dots--dot mx-1"></div>
                  </center>
                </div>
              )}
              <div
                className="col-4 mt-5 mb-5 subscription-plan-card card p-4"
                md={6}
                sm={12}
              >
                <div className="">
                  <div className="">
                    {/* <img
                    src={selectedStore?.image}
                    className="mt-3"
                    style={{ width: "100%", height: "100%" }}
                  ></img> */}
                    {/* <div className="products-header-image-containers m-0 p-0">
                    <div style={{ position: "relative", width: "100%" }}>
                      <ProductImageSlider productImage={productImage} />
                    </div>
                  </div> */}

                    <div className="products-header-image ">
                      {getNameAndTypes(SelectedImage)?.type === ".mp4" ||
                      getNameAndTypes(SelectedImage)?.type === ".webm" ||
                      getNameAndTypes(SelectedImage)?.type === ".mov" ||
                      getNameAndTypes(SelectedImage)?.type === ".avi" ||
                      getNameAndTypes(SelectedImage)?.type === ".m4v" ||
                      getNameAndTypes(SelectedImage)?.type === ".mp3" ||
                      getNameAndTypes(SelectedImage)?.type === ".mkv" ||
                      getNameAndTypes(SelectedImage)?.type === ".m4a" ||
                      getNameAndTypes(SelectedImage)?.type === ".mpg" ? (
                        <div
                          style={{ height: "400px", borderRadius: "15px" }}
                          key={Idx}
                        >
                          <Vid
                            id={Idx}
                            src={SelectedImage}
                            play={Idx === curIdx}
                          />
                        </div>
                      ) : (
                        <img
                          title="background_image"
                          alt="background_image"
                          src={SelectedImage}
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      )}
                      {/* <div
                        className="overlay p-4 m-4"
                        style={{
                          marginTop: "10%",
                          height: "43%",
                          width: "90%",
                          // marginLeft: "2%",
                          // marginRight: "2%",

                          backgroundColor: "rgba(0, 0, 0, 0.2)",
                        }}
                      ></div> */}
                    </div>
                    <div className=" mt-5">
                      <div className=" mx-4 mt-3 " style={{ alignItems: "" }}>
                        {partnersData.length !== 0 && (
                          <Slider
                            {...settings}
                            infinite
                            // beforeChange={(cur, next) => {
                            //   setCurIdx(next);
                            // }}
                          >
                            {partnersData?.map((partner, index) => (
                              <div
                                className="col-sm-1"
                                key={index}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setCurIdx(index);
                                  setIdx(index);
                                  setSelectedImage(partner);
                                }}
                                style={{
                                  borderRadius: "0px 0px 10px 10px",
                                  background: "#FFF",
                                  boxShadow:
                                    "0px 0px 2px 0px rgba(0, 0, 0, 0.8)",
                                }}
                              >
                                {getNameAndTypes(partner)?.type === ".mp4" ||
                                getNameAndTypes(partner)?.type === ".webm" ||
                                getNameAndTypes(partner)?.type === ".mov" ||
                                getNameAndTypes(partner)?.type === ".avi" ||
                                getNameAndTypes(partner)?.type === ".m4v" ||
                                getNameAndTypes(partner)?.type === ".mp3" ||
                                getNameAndTypes(partner)?.type === ".mkv" ||
                                getNameAndTypes(partner)?.type === ".m4a" ||
                                getNameAndTypes(partner)?.type === ".mpg" ? (
                                  <video
                                    width="100%"
                                    // controls
                                    data-testid={`video-element`}
                                    borderRadius="25px"
                                    style={{
                                      height: "110px",
                                      width: "110px",
                                    }}
                                  >
                                    <source src={partner} type="video/mp4" />
                                  </video>
                                ) : (
                                  <img
                                    title={`background_image`}
                                    alt={`background_image`}
                                    src={partner}
                                    style={{
                                      height: "110px",
                                      width: "110px",
                                    }}
                                  />
                                )}
                              </div>
                            ))}
                          </Slider>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="h3">{selectedStore?.name}</div>
                    {/* <div className="p fs-16">{selectedStore?.description}</div> */}
                    <span
                      className="selected-strategy-description"
                      dangerouslySetInnerHTML={{
                        __html: selectedStore?.description,
                      }}
                    ></span>
                  </div>
                  {/* <div
                    className="row mt-4"
                    style={{
                      borderBottom: "1px solid rgba(167, 167, 167, 0.6)",
                    }}
                  >
                    <div className="h5">Payment by</div>
                    <div className="row">
                      <img
                        src={Badge}
                        alt="payment-by-img"
                        title="payment-by-img"
                        style={{ height: "100px", width: "100px" }}
                      ></img>
                    </div>
                  </div> */}
                  {/* <div
                    className="stripeCol20 p-0 m-0"
                    style={{ width: "100%" }}
                  ></div> */}
                  <div className="row mt-1">
                    <div className="col p pt-3 fs-20">Strategy price</div>
                    <div className="col stripeCol27 fs-20 pt-3">
                      ${selectedStore?.price?.toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8 " md={6} sm={12}>
                {!paymentFailed && (
                  <>
                    {strategyStatus !== "true" && (
                      <div>
                        {allPlan && (
                          <div className="row m-0 p-0 subscription-plan mb-3">
                            {subscriptionPlans?.map((element, index) => (
                              <>
                                <Col
                                  className="mb-3"
                                  md={6}
                                  sm={12}
                                  style={{ height: "fit-content" }}
                                >
                                  <div className="subscription-plan-card mb-1">
                                    <div className="subscription-plan-card-body">
                                      <h2 className="text-center text-title font-weight-bold">
                                        {element?.subscription_plan_type ===
                                        "month"
                                          ? "Monthly plan"
                                          : "Yearly plan"}
                                      </h2>
                                      <h3 className="font-weight-bold">
                                        $
                                        {element?.per_user_price?.toLocaleString(
                                          "en-US"
                                        )}
                                      </h3>

                                      <div className="mx-2 bg-primary"></div>
                                      <ul className="list-unstyled my-3 text-small text-left mx-5">
                                        <li className="mb-3 text-dark fs-16 p text-start ">
                                          <i className="fa fa-check mr-2 text-primary"></i>{" "}
                                          {element?.discription}
                                        </li>
                                        <li className="mb-2 text-dark fs-16 text-start">
                                          <i className="fa fa-check mr-2 text-primary"></i>{" "}
                                          Discount - {element?.discount_percent}{" "}
                                          %
                                        </li>
                                        <li className="mb-2 text-dark fs-16 text-start">
                                          <i className="fa fa-check mr-2 text-primary"></i>{" "}
                                          Free trial period -{" "}
                                          {element?.free_trial_period} &nbsp;
                                          day's
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="m-0 p-0 ps-5 pb-3">
                                      <h4 className="mb-2 fs-18">
                                        Additional license -{" "}
                                        {index === Index && selectedUsers}
                                      </h4>

                                      <div className="radio ">
                                        <label
                                          className="p  fs-16 "
                                          style={{ cursor: "pointer" }}
                                        >
                                          <input
                                            className="mx-3 p"
                                            type="radio"
                                            value="1"
                                            // checked={selectedUsers === "1"}
                                            checked={
                                              selectedUsers === "1" &&
                                              index === Index
                                            }
                                            onChange={(e) =>
                                              onValueChange(
                                                e,
                                                index,
                                                element?.subscription_plan_type
                                              )
                                            }
                                          />
                                          1 Users (${" "}
                                          {(element?.per_user_price * 1)
                                            ?.toFixed(2)
                                            ?.toLocaleString("en-US") || 0}
                                          )
                                        </label>
                                      </div>

                                      <div className="radio ">
                                        <label
                                          className="p fs-16 "
                                          style={{ cursor: "pointer" }}
                                        >
                                          <input
                                            className="mx-3 p"
                                            type="radio"
                                            value="10"
                                            // checked={selectedUsers === "10"}
                                            // onChange={onValueChange}
                                            checked={
                                              selectedUsers === "10" &&
                                              index === Index
                                            }
                                            onChange={(e) =>
                                              onValueChange(
                                                e,
                                                index,
                                                element?.subscription_plan_type
                                              )
                                            }
                                          />
                                          10 Users ($
                                          {(element?.per_user_price * 10)
                                            ?.toFixed(2)
                                            ?.toLocaleString("en-US") || 0}
                                          )
                                        </label>
                                      </div>

                                      <div className="radio">
                                        <label
                                          className="p fs-16"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <input
                                            className="mx-3 p"
                                            type="radio"
                                            value="25"
                                            // checked={selectedUsers === "25"}
                                            // onChange={onValueChange}
                                            checked={
                                              selectedUsers === "25" &&
                                              index === Index
                                            }
                                            onChange={(e) =>
                                              onValueChange(
                                                e,
                                                index,
                                                element?.subscription_plan_type
                                              )
                                            }
                                          />
                                          25 Users ($
                                          {(element?.per_user_price * 25)
                                            ?.toFixed(2)
                                            ?.toLocaleString("en-US") || 0}
                                          )
                                        </label>
                                      </div>

                                      <div className="radio">
                                        <label
                                          className="p fs-16"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <input
                                            className="mx-3 p"
                                            type="radio"
                                            value="50"
                                            // checked={selectedUsers === "50"}
                                            // onChange={onValueChange}
                                            checked={
                                              selectedUsers === "50" &&
                                              index === Index
                                            }
                                            onChange={(e) =>
                                              onValueChange(
                                                e,
                                                index,
                                                element?.subscription_plan_type
                                              )
                                            }
                                          />
                                          50 Users ($
                                          {(element?.per_user_price * 50)
                                            ?.toFixed(2)
                                            ?.toLocaleString("en-US") || 0}
                                          )
                                        </label>
                                      </div>

                                      <div className="mx-1">
                                        <Link
                                          className="fs-14 text-blue mb-3"
                                          onClick={() => {
                                            setinputField(!inputField);
                                            setIndex(index);
                                          }}
                                        >
                                          Number of users
                                        </Link>

                                        {inputField && index === Index && (
                                          <div className="radio">
                                            <input
                                              type="number"
                                              className="figma-input-num  p "
                                              style={{ width: "200px" }}
                                              name="user"
                                              placeholder="Enter no of user"
                                              autocomplete="off"
                                              min={0}
                                              value={selectedUsers}
                                              onChange={(e) => {
                                                setSelectedUser(
                                                  e?.target?.value
                                                );
                                                setFinalPlan(
                                                  element?.subscription_plan_type
                                                );
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>

                                      <h4 className="my-3 mb-4">
                                        Total amount : $
                                        {(index === Index &&
                                          (
                                            selectedUsers *
                                            element?.per_user_price
                                          )
                                            ?.toFixed(2)
                                            ?.toLocaleString("en-US")) ||
                                          0}
                                      </h4>
                                    </div>
                                  </div>
                                </Col>
                              </>
                            ))}
                            {subscriptionPlans?.length === 0 && (
                              <h4 className="text-center">
                                Subscription plans not available for now !
                              </h4>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      className="row float-center my-5"
                      style={{ padding: "0rem" }}
                    >
                      <Col></Col>
                      <Col>
                        <div className="subscription-plan-card mb-1">
                          <div className="subscription-plan-card-body">
                            {subscriptionPlans?.map(
                              (element, index) =>
                                index == Index && (
                                  <>
                                    <h4
                                      style={{ color: "black" }}
                                      className="text-start"
                                    >
                                      Total amount : $
                                      {strategyStatus !== "true" &&
                                      JSON.parse(localStorage.getItem("store"))
                                        .SelectedStrategy.type === "premium"
                                        ? (
                                            selectedStore?.price +
                                            selectedUsers *
                                              element?.per_user_price
                                          )
                                            ?.toFixed(2)
                                            ?.toLocaleString("en-US")
                                        : JSON.parse(
                                            localStorage.getItem("store")
                                          ).SelectedStrategy.type === "premium"
                                        ? (selectedStore?.price)
                                            .toFixed(2)
                                            ?.toLocaleString("en-US")
                                        : null}
                                      {strategyStatus !== "true" &&
                                      JSON.parse(localStorage.getItem("store"))
                                        .SelectedStrategy.type != "premium"
                                        ? (
                                            selectedUsers *
                                            element?.per_user_price
                                          )
                                            ?.toFixed(2)
                                            ?.toLocaleString("en-US")
                                        : JSON.parse(
                                            localStorage.getItem("store")
                                          ).SelectedStrategy.type != "premium"
                                        ? 0
                                        : null}
                                    </h4>
                                    {strategyStatus !== "true" && (
                                      <div>
                                        <h4 className="text-start">
                                          Subscription plan's :{" "}
                                          {FinalPlan != "" ? (
                                            FinalPlan
                                          ) : (
                                            <span>No plan selected</span>
                                          )}
                                        </h4>
                                        <h4 className="text-start">
                                          Number of license :{" "}
                                          {index === Index &&
                                          selectedUsers != null ? (
                                            selectedUsers
                                          ) : (
                                            <span>No license selected</span>
                                          )}
                                        </h4>
                                      </div>
                                    )}
                                    {/* {strategyStatus !== "true" && (
                                      <button
                                        type="button"
                                        onClick={discardHandler}
                                        className="figma-btn text-white text-end"
                                        style={{
                                          width: "auto",
                                          paddingRight: "5px",
                                          paddingLeft: "5px",
                                          border: "none",
                                        }}
                                      >
                                        Discard Subscription
                                      </button>
                                    )} */}
                                  </>
                                )
                            )}
                          </div>
                        </div>

                        {strategyStatus !== "true" ||
                        (strategyStatus === "true" &&
                          JSON.parse(localStorage.getItem("store"))
                            .SelectedStrategy.type !== "standard") ? (
                          <button
                            className="createOrganizationButton "
                            type="submit"
                            style={{
                              border: "none",
                              // background: "#1717af",
                              padding: 0,
                              margin: "30px",
                              // width:"100%"
                            }}
                            onClick={(e) => {
                              handlePaymentSubmit(e);
                            }}
                          >
                            Proceed to Pay
                          </button>
                        ) : (
                          <button
                            className="createOrganizationButton "
                            type="submit"
                            style={{
                              border: "none",
                              background: "#1717af",
                              padding: 0,
                              margin: "30px",
                              // width:"100%"
                            }}
                            onClick={(e) => {
                              handlePaymentSubmit(e);
                            }}
                          >
                            Add strategy
                          </button>
                        )}
                      </Col>
                      <Col></Col>
                    </div>
                  </>
                )}
                {paymentFailed && (
                  <PaymentFail handlePaymentRetry={handlePaymentSubmit} />
                )}
              </div>
            </div>
            {/* <div className="row bg-white">
              <button
                className="createOrganizationButton mb-5"
                type="submit"
                style={{
                  border: "none",
                  background: "#1717af",
                  padding: 0,
                  marginLeft: "30%",
                  // margin: "30px",
                  width: "40%",
                }}
                onClick={(e) => {
                  handlePaymentSubmit(e);
                }}
              >
                Proceed to Pay
              </button>
            </div> */}
            {/* </form> */}
          </>
        )}
      </div>
    </>
  );
};

export default StripePaymentForm;
