
import {
    createGoal,
    getGoals,
    deleteGoal,
    getTasksByGoal,
    editGoal
} from '../../services/GoalService';
import { toast } from "react-toastify";

import {
    formatError,
} from '../../services/AuthService';
import { Link } from 'react-router-dom';

export const CREATE_GOAL_CONFIRMED_ACTION = '[createGoal action] confirmed createGoal';
export const CREATE_GOAL_FAILED_ACTION = '[createGoal action] failed createGoal';
export const EDIT_GOAL_CONFIRMED_ACTION = '[editGoal action] confirmed editGoal';
export const EDIT_GOAL_FAILED_ACTION = '[editGoal action] failed editGoal';
export const GET_GOALS_CONFIRMED_ACTION = '[getGoals action] confirmed getGoals';
export const GET_GOALS_FAILED_ACTION = '[getGoals action] failed getGoals';
export const DELETE_GOAL_CONFIRMED_ACTION = '[deleteGoal action] confirmed deleteGoal';
export const DELETE_GOAL_FAILED_ACTION = '[deleteGoal action] failed deleteGoal';
export const GET_TASKS_BY_GOAL_CONFIRMED_ACTION = '[getTasksByGoal action] confirmed getTasksByGoal';
export const GET_TASKS_BY_GOAL_FAILED_ACTION = '[getTasksByGoal action] failed getTasksByGoal';
export const RESET_GOAL_PAGE = 'reset goal page to initial state';

// export const SET_ORG_MODAL_ACTION = '[setOrgData action] confirmed setOrgData';

export function createGoalAction(org_id, user_id, goal_owner_id, teamId, goal_name, goal_description, goalAlignId, attachment, token, isSuperParent, priorityNo, status) {
    console.log(teamId, "teamiddddddddddddd")
    if (!goal_owner_id) { goal_owner_id = user_id }
    // if (scope != 'team') {
    //     teamId = ''
    // }

    return (dispatch) => {
        createGoal(org_id, user_id, goal_owner_id, teamId, goal_name, goal_description, goalAlignId, attachment, token, isSuperParent, priorityNo, status)
            .then((response) => {
                dispatch(createGoalConfirmAction(response.data));
                toast(
                    <>
                      <div className="m-1 d-flex">
                        <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
                        <div>
                          <h2 style={{
                            fontSize: "22px"
                          }}>
                            Completed
                          </h2>
                          <h3 style={{
                            // color: "#131F4C",
                            fontSize: "18px"
                          }}>
                            Your goal has been created successfully! <br/> Click {" "}
                            <Link
                                style={{ cursor: "pointer", color: "red" }}
                                to={"/tasks"}
                                state={{ goalId: response?.data?.data }}
                                className="badge badge-light"
                            >
                                <u>here </u>
                            </Link>  {" "}
              {/* <span style={{color:'red'}} onClick={() => handleCreateTask(dispatch, response?.data?.data, token)}>here</span>  */}
              to create task for goal.
                          </h3>
                        </div>
                      </div>
                    </>,
                    {
                      toastId: "goal-create",
                      className: 'toast-success-container toast-success-container-after ',
                    }
                  )

            })
            .catch((error) => {
                console.log('error', error?.response?.data?.message)
                // const errorMessage = formatError(error?.response?.data);
                // dispatch(createGoalFailedAction(errorMessage));
                toast(
                    <>
                      <div className="m-1 d-flex">
                      <i class="bi bi-check-circle fs-26 me-3" style={{color: "#131F4C"}}></i>
                      <div>
                        <h2  style={{
                          fontSize: "22px" }}>
                          Failed
                        </h2>
                        <h3 style={{
                          fontSize: "18px" }}>
                        {error?.response?.data?.message}
                        </h3>
                        </div>
                      </div>
                    </>,
                    { 
                      toastId: "task-create",
                      className: 'toast-error-container toast-error-container-after ',
                    }
                  )
            });
    };

}

function handleCreateTask(dispatch, goal, token) {
    // Define the task details as required
   
    console.log('dispatch, goal, token', dispatch,goal,token)
}

export function createGoalConfirmAction(data) {
    return {
        type: CREATE_GOAL_CONFIRMED_ACTION,
        payload: data,
    };
}
export function createGoalFailedAction(message) {
    return {
        type: CREATE_GOAL_FAILED_ACTION,
        payload: message,
    }
}

export function editGoalAction(orgId, goalOwner_id, teamId, goalDetails, goalIdDetails, goalAlignId, token, isSuperParent,priorityNo, status) {
    return (dispatch) => {
        // if (scope != "team") {
        //     teamId = ""
        // }
        // if(scope == "organization"){
        //     goalAlignId = ""
        // }
        editGoal(orgId, goalOwner_id, teamId, goalDetails, goalIdDetails, goalAlignId, token, isSuperParent,priorityNo, status)
            .then((response) => {
                dispatch(editGoalConfirmAction(response.data));
            })
            .catch((error) => {
                console.log('errorwwwwwwwwwwwwwwwwwwwwwwww', error.response.data.message)
                toast(
                    <>
                      <div className="m-1 d-flex">
                      <i class="bi bi-check-circle fs-26 me-3" style={{color: "#131F4C"}}></i>
                      <div>
                        <h2  style={{
                          fontSize: "22px" }}>
                          Failed
                        </h2>
                        <h3 style={{
                          fontSize: "18px" }}>
                        {error?.response?.data?.message}
                        </h3>
                        </div>
                      </div>
                    </>,
                    { 
                      toastId: "task-create",
                      className: 'toast-error-container toast-error-container-after ',
                    }
                  )
                const errorMessage = formatError(error.response.data);
                dispatch(editGoalFailedAction(errorMessage));
               

               
            });
    };

}

export function editGoalConfirmAction(data) {
    return {
        type: EDIT_GOAL_CONFIRMED_ACTION,
        payload: data,
    };
}

export function editGoalFailedAction(message) {
    return {
        type: EDIT_GOAL_FAILED_ACTION,
        payload: message,
    }
}


export function getGoalByScope(scope, org_id, teamId, token) {
    return (dispatch) => {
        getGoals(scope, org_id, teamId, token)
            .then((response) => {
                dispatch(getGoalsConfirmAction(response.data));
            })
            .catch((error) => {
                console.log('error', error)
                const errorMessage = formatError(error.response.data);
                dispatch(getGoalsFailedAction(errorMessage));
            });
    };

}

export function getGoalsConfirmAction(data) {
    return {
        type: GET_GOALS_CONFIRMED_ACTION,
        payload: data,
    };
}
export function getGoalsFailedAction(data) {
    return {
        type: GET_GOALS_FAILED_ACTION,
        payload: data,
    }
}


export function deleteGoalAction(goal_id, token) {
    return (dispatch) => {
        deleteGoal(goal_id, token)
            .then((response) => {
                dispatch(deleteGoalConfirmAction(response.data));
            })
            .catch((error) => {
                console.log('error', error)
                const errorMessage = formatError(error.response.data);
                dispatch(deleteGoalFailedAction(errorMessage));
            });
    };

}

export function deleteGoalConfirmAction(data) {
    return {
        type: DELETE_GOAL_CONFIRMED_ACTION,
        payload: data,
    };
}
export function deleteGoalFailedAction(data) {
    return {
        type: DELETE_GOAL_FAILED_ACTION,
        payload: data,
    }
}

export function getTasksByGoalIdAction(goal_id, token) {
    return (dispatch) => {
        getTasksByGoal(goal_id, token)
            .then((response) => {
                dispatch(tasksByGoalConfirmAction(response.data));
            })
            .catch((error) => {
                console.log('error', error)
                const errorMessage = formatError(error.response.data);
                dispatch(tasksByGoalFailedAction(errorMessage));
            });
    };

}

export function tasksByGoalConfirmAction(data) {
    return {
        type: GET_TASKS_BY_GOAL_CONFIRMED_ACTION,
        payload: data,
    };
}
export function tasksByGoalFailedAction(data) {
    return {
        type: GET_TASKS_BY_GOAL_FAILED_ACTION,
        payload: data,
    }
}


export function resetGoalPage(data) {
    return {
        type: RESET_GOAL_PAGE,
        payload: {
            data: data,
        },


    }
}
// export function setOrgModelPopup(data) {
//     return {
//         type: SET_ORG_MODAL_ACTION,
//         payload: data,
//     };
// }