
import {
    getAllThreadRoomById
} from '../../services/messageService';


export const READ_NOTIFICATION_CONFIRMED_ACTION = '[readNotification action] confirmed readNotification';
export const GET_THREADS_CONFIRMED_ACTION = '[getAllThreadRoomById action] confirmed getAllThreadRoomById';
export const GET_THREADS_FAILED_ACTION = '[getAllThreadRoomById action] failed getAllThreadRoomById';
export const GET_STRATEGY_IMPORTED_ACTION = '';
export const ALL_THREADS_WITH_CHAT = "ALL_THREADS_WITH_CHAT";
export const REFRESH_THREADS_LIST = "REFRESH_THREADS_LIST";
export const UPDATE_ALL_THREADS_WITH_CHAT = "UPDATE_ALL_THREADS_WITH_CHAT";
export const LOAD_MORE_THREADS = "LOAD_MORE_THREADS";
export const FETCH_NEXT_THREAD_CHATS = "FETCH_NEXT_THREAD_CHATS";
export const CREATED_NEW_THREAD_WITH_GOTO_CHAT = "CREATED_NEW_THREAD_WITH_GOTO_CHAT";
export const FETCH_ALL_MY_DAY_DATA = "FETCH_ALL_MY_DAY_DATA";
export const FETCH_GOALS_PROGRESS = "FETCH_GOALS_PROGRESS";
export const FETCH_ALL_TASKS_DATA_ON_MYDAY = "FETCH_ALL_TASKS_DATA_ON_MYDAY";
export const UPDATE_THREAD_LIST = "UPDATE_THREAD_LIST";
export const UPDATE_BADGE_COUNTER = "UPDATE_BADGE_COUNTER";

export function readNotificationConfirmAction(id) {
    return (dispatch) => {
        dispatch(readNotificationAction(id));
    };
}

export function readNotificationAction(id) {
    return {
        type: READ_NOTIFICATION_CONFIRMED_ACTION,
        payload: id,
    };
}

export const FILE_UPLOAD_CONFIRMED_ACTION = '[fileUpload action] confirmed fileUpload';


export function fileUploadConfirmAction(id) {
    return (dispatch) => {
        dispatch(fileUploadAction(id));
    };
}

export function fileUploadAction(id) {
    return {
        type: FILE_UPLOAD_CONFIRMED_ACTION,
        payload: id,
    };
}

export function getThreadList(orgId,
    start,
    end,
    token) {
    return (dispatch) => {
        getAllThreadRoomById(orgId,
            start,
            end,
            token)
            ?.then((response) => {
                dispatch(getThreadConfirmAction(response));
            })
            ?.catch((error) => {
                dispatch(getThreadFailedAction(error));
            });

    };
}
export function getThreadConfirmAction(message) {
    return {
        type: GET_THREADS_CONFIRMED_ACTION,
        payload: message,
    };
}
export function getThreadFailedAction(message) {
    return {
        type: GET_THREADS_FAILED_ACTION,
        payload: message,
    }
}
export function setStrategyImportedStatus(strategyImported) {
    return {
        type: GET_STRATEGY_IMPORTED_ACTION,
        payload: strategyImported,
    }
}

export function allThreadsWithChatMessages(data) {
    return {
        type: ALL_THREADS_WITH_CHAT,
        payload: data,
    }
}

export function refreshThreadsList(data) {
    return {
        type: REFRESH_THREADS_LIST,
        payload: data,
    }
}

export function updateAllThreadsWithChatMessages(data) {
    return {
        type: UPDATE_ALL_THREADS_WITH_CHAT,
        payload: data,
    }
}

export function updateBadgeCounter(data) {
    return {
        type: UPDATE_BADGE_COUNTER,
        payload: data,
    }
}

export function loadMoreThreads(data) {
    return {
        type: LOAD_MORE_THREADS,
        payload: data,
    }
}

export function fetchNextThreadChats(data) {
    return {
        type: FETCH_NEXT_THREAD_CHATS,
        payload: data,
    }
}

export function createdNewThreadWithGoToChat(data) {
    return {
        type: CREATED_NEW_THREAD_WITH_GOTO_CHAT,
        payload: data,
    }
}

export function fetchAllMyDayData(data) {
    return {
        type: FETCH_ALL_MY_DAY_DATA,
        payload: data,
    }
}

export function fetchGoalsProgressData(data) {
    return {
        type: FETCH_GOALS_PROGRESS,
        payload: data,
    }
}

export function fetchAllTasksDataOnMyDay(data) {
    return {
        type: FETCH_ALL_TASKS_DATA_ON_MYDAY,
        payload: data,
    }
}

export function updateThreadList(data) {
    return {
        type: UPDATE_THREAD_LIST,
        payload: data,
    }
}

