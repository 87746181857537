import React, { useState, useEffect, useRef, memo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Row, Modal } from "react-bootstrap";
import groupImage from "../../../images/groupImage.jpeg";
import org1 from "../../../images/org1.png";
import profilePicture from "../../../images/profile/profilePic.png";
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from "react-select";
import { getSearchResults } from "../../../services/GoalService";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/Component/LoadingSpinner";

const SearchModal = (props) => {
  const location = useLocation();
  const [LoadMore, setLoadMore] = useState(false);
  const [LoadMoreButton, setLoadMoreButton] = useState(true);
  const [loader, setLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchKey, setSearchKey] = useState();
  const userDetails = localStorage?.getItem("userDetails");
  const userInfoDetails = JSON?.parse(userDetails);
  const [activeTab, setActiveTab] = useState("tab1");
  const [selectedSearchResult, setSelectedSearchresult] = useState([]);
  const [type, setType] = useState("all");
  const navigate = useNavigate();
  const [selectedAttachmentScope, setSelectedAttachmentScope] = useState({
    label: "Goal",
    value: "goal",
  });

  const [storedValue, setStoredValue] = useState("");

  const modalRef = useRef();
  const searchInput = useRef(null);
  const storeAttachmentScope = (scope) => {
    setSelectedAttachmentScope(scope);
  };

  useEffect(() => {
    if (searchInput?.current) {
      searchInput?.current?.focus();
    }
  }, []);

  const attachmentScope = [
    {
      label: "Goal",
      value: "goal",
    },
    {
      label: "Task",
      value: "task",
    },
    {
      label: "Thread",
      value: "thread",
    },
  ];

  const getNameAndTypes = (file) => {
    if (file) {
      var filename = file?.substring(file?.lastIndexOf("/") + 1);
      var type = filename?.substring(filename?.lastIndexOf("."));
      return { name: filename, type: type };
    }
  };

  const changedDateFormats = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var today1 = new Date(date);
    return (
      days[today1?.getDay()] +
      " " +
      monthNames[today1?.getMonth()] +
      " " +
      today1?.getFullYear()
    );
  };

  const getTimeZone = (date) => {
    var today1 = new Date(date);
    let dates = today1?.toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
    }); //.split(',')[1]
    return getTwentyFourHourTime(dates);
  };

  const handleTab1 = () => {
    setSelectedSearchresult([]);
    setType("all");
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    setSelectedSearchresult([]);
    setType("task");
    setActiveTab("tab2");
  };
  const handleTab3 = () => {
    setSelectedSearchresult([]);
    setType("goal");
    setActiveTab("tab3");
  };
  const handleTab4 = () => {
    setSelectedSearchresult([]);
    setType("message");
    setActiveTab("tab4");
  };
  const handleTab5 = () => {
    setSelectedSearchresult([]);
    setType("attachment");
    setActiveTab("tab5");
  };
  const handleTab6 = () => {
    setSelectedSearchresult([]);
    setType("people");
    setActiveTab("tab6");
  };
  const handleTab7 = () => {
    setSelectedSearchresult([]);
    setType("team");
    setActiveTab("tab7");
  };
  const handleTab8 = () => {
    setSelectedSearchresult([]);
    setType("organization");
    setActiveTab("tab8");
  };

  useEffect(() => {
    setSelectedSearchresult(
      Array.isArray(selectedSearchResult) ? selectedSearchResult || [] : []
    );
    navigate(location?.pathname);
  }, [searchKey]);

  useEffect(() => {
    setLoader(true);
    setSearchKey(() => searchKey);
    setSelectedAttachmentScope(selectedAttachmentScope);
    getSearchedResults(
      type,
      0,
      5,
      searchKey,
      activeTab === "tab5" ? selectedAttachmentScope?.value : ""
    );
  }, [searchKey, activeTab, selectedAttachmentScope]);

  useEffect(() => {
    if (LoadMore) {
      setLoader(true);
      getSearchedResults(
        type,
        0,
        selectedSearchResult?.length + 5,
        searchKey,
        activeTab === "tab5" ? selectedAttachmentScope?.value : ""
      );
    }
  }, [LoadMore]);

  useEffect(() => {
    setSelectedItem(selectedItem);
  }, [selectedItem]);

  const handleOutsideClick = (event) => {
    props?.ModalClick(() => false);
    props?.setSearchOpen(() => false);
    setSearchKey(searchKey);
  };

  const getSearchedResults = async (
    type,
    start,
    end,
    search,
    attachmentType
  ) => {
    try {
      setLoader(true);

      const res = await getSearchResults(
        type,
        start,
        end,
        search,
        attachmentType,
        userInfoDetails?.data?.accessToken
      );

      setLoader(false);

      if (res?.data?.data) {
        setSelectedSearchresult(res.data.data ? res.data.data || [] : []);

        if (res.data.data.length < 5 || res.data.data.length <= end) {
          setLoadMoreButton(false);
        } else {
          setLoadMoreButton(true);
        }
      }
    } catch (err) {
      setLoader(false);
      console.error("Error fetching search results:", err);
    }
  };

  function getTwentyFourHourTime(date) {
    var d = new Date(date);
    return d?.getHours() + ":" + d?.getMinutes();
  }

  const showGoals = (event, goal) => {
    event?.preventDefault();
    props?.setSearchOpen(false);
    props?.ModalClick(false);
    navigate("/goals", { state: { goal: goal, key: true } });
  };

  const showTasks = (event, task) => {
    event.preventDefault();
    props?.setSearchOpen(false);
    props?.ModalClick(false);
    navigate("/tasks", {
      state: { task: task, key: task?.id, statusKey: task?.task_status },
    });
  };

  const showTeams = (event, team) => {
    event?.preventDefault();
    if (!team?.team_parent) {
      navigate("/teams", { state: { Team: team, key: team?.id } });
    } else {
      navigate("/subTeams", {
        state: { Team: team, key: team?.id, parent: team?.team_parent },
      });
    }
    props?.setSearchOpen(false);
    props?.ModalClick(false);
  };

  const showMessages = (event, message) => {
    event?.preventDefault();
    navigate("/messages", {
      state: { selectedUser: message?.thread, message: message },
    });
    props?.setSearchOpen(false);
    props?.ModalClick(false);
  };

  const showOrganizations = (event, Org) => {
    event.preventDefault();
    navigate("/organization-list", { state: { Org: Org, key: Org?.id } });
    props?.setSearchOpen(false);
    props?.ModalClick(false);
  };

  const showUsers = (event, user) => {
    event?.preventDefault();
    navigate("/invite-users", { state: { user: user } });
    props?.setSearchOpen(false);
    props?.ModalClick(false);
  };

  const setFunction = (e, goal) => {
    if (goal?.goal_owner) {
      showGoals(e, goal);
    } else if (goal?.task_owner) {
      showTasks(e, goal);
    } else if (goal?.team_manager) {
      showTeams(e, goal);
    } else if (goal?.org_owner) {
      showOrganizations(e, goal);
    } else if (goal?.auth_user) {
      showUsers(e, goal);
    }
  };

  const handleBlur = (event) => {
    setStoredValue(event?.target?.value);
  };

  const handleFocus = (event) => {
    event.target.value = storedValue;
  };
  return (
    <>
      <Modal
        ref={modalRef}
        onHide={handleOutsideClick}
        show={props?.searchOpen}
        className="fade"
        size="xl"
      >
        <div className="d-flex m-4 mb-3">
          <Col className="mx-1">
            <div className="search m-0" style={{ width: "100%" }}>
              <input
                name="search_box"
                type="text" //id="form1"
                className="searchTerm px-2"
                placeholder=" Search "
                value={searchKey}
                onChange={(event) => setSearchKey(event.target.value)}
                onBlur={handleBlur}
                onFocus={handleFocus}
                autoFocus
                autocomplete="off"
              />
              <button type="submit" class="searchButton">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </Col>
          <button
            variant=""
            className="btn-close float-end align-center btn-danger m-1 p-2"
            onClick={() => {
              props?.setSearchOpen(false);
              props?.ModalClick(false);
            }}
          ></button>
        </div>
        {
          <div
            align="center"
            className="dropdown-menu-end show mb-2"
            style={{
              backgroundColor: "#f1f2f3",
            }}
          >
            {searchKey?.length && selectedSearchResult ? (
              <>
                <Row className="m-0 p-0">
                  <PerfectScrollbar
                    id="scrollactive"
                    className="widget-media dlab-scroll px-3"
                    option={{ suppressScrollY: true }}
                  >
                    <ul
                      className="search pt-2 p-0"
                      style={{ borderBottom: "0px solid #ccc" }}
                    >
                      <li
                        className={activeTab === "tab1" ? "active" : ""}
                        onClick={handleTab1}
                        style={{
                          fontSize: window?.innerWidth <= 768 ? "1rem" : "1vw",
                        }}
                      >
                        All
                      </li>
                      <li
                        className={activeTab === "tab2" ? "active" : ""}
                        onClick={handleTab2}
                        style={{
                          fontSize: window?.innerWidth <= 768 ? "1rem" : "1vw",
                        }}
                      >
                        Task
                      </li>
                      <li
                        className={activeTab === "tab3" ? "active" : ""}
                        onClick={handleTab3}
                        style={{
                          fontSize: window.innerWidth <= 768 ? "1rem" : "1vw",
                        }}
                      >
                        Goal
                      </li>
                      <li
                        className={activeTab === "tab4" ? "active" : ""}
                        onClick={handleTab4}
                        style={{
                          fontSize: window?.innerWidth <= 768 ? "1rem" : "1vw",
                        }}
                      >
                        Message
                      </li>
                      <li
                        className={activeTab === "tab5" ? "active" : ""}
                        onClick={handleTab5}
                        style={{
                          fontSize: window?.innerWidth <= 768 ? "1rem" : "1vw",
                        }}
                      >
                        Attachments
                      </li>
                      <li
                        className={activeTab === "tab6" ? "active" : ""}
                        onClick={handleTab6}
                        style={{
                          fontSize: window?.innerWidth <= 768 ? "1rem" : "1vw",
                        }}
                      >
                        People
                      </li>
                      <li
                        className={activeTab === "tab7" ? "active" : ""}
                        onClick={handleTab7}
                        style={{
                          fontSize: window?.innerWidth <= 768 ? "1rem" : "1vw",
                        }}
                      >
                        Team
                      </li>
                      <li
                        className={activeTab === "tab8" ? "active" : ""}
                        onClick={handleTab8}
                        style={{
                          fontSize: window?.innerWidth <= 768 ? "1rem" : "1vw",
                        }}
                      >
                        Organization
                      </li>
                    </ul>
                  </PerfectScrollbar>
                  {activeTab === "tab5" && (
                    <Row className="m-1">
                      <Col className="d-flex justify-content-center">
                        <Select
                          className="m-2 w-100 w-md-auto" // Set width to 100% on small screens and auto on medium and larger screens
                          name="goal_owner"
                          options={attachmentScope}
                          styles={{
                            padding: "1px",
                            borderRadius: "10px",
                            menu: (provided) => ({
                              ...provided,
                              width: "100%", // Set the width of the dropdown menu
                            }),
                          }}
                          defaultValue={selectedAttachmentScope}
                          onChange={(e) => storeAttachmentScope(e)}
                        />
                      </Col>
                    </Row>
                  )}
                </Row>

                <PerfectScrollbar
                  id="scrollactive"
                  className="widget-media dlab-scroll px-3 body-scrollbar"
                  style={{ maxHeight: "50vh", minHeight: "15vh" }}
                >
                  {loader ? (
                    <LoadingSpinner
                      data={{ padding: "10px", marginY: "120px" }}
                    />
                  ) : selectedSearchResult?.length !== 0 &&
                    selectedSearchResult != null ? (
                    <>
                      {activeTab === "tab1" ? (
                        <>
                          <Row className="d-flex ms-3">
                            Task :
                            <Row>
                              {selectedSearchResult?.tasks?.length > 0 ? (
                                selectedSearchResult?.tasks?.map(
                                  (task, index) => (
                                    <>
                                      <div
                                        className="timeline m-1 p-0"
                                        style={{
                                          borderRadius: "10px",
                                          cursor: "pointer",
                                          backgroundColor: "#ffffff",
                                        }}
                                      >
                                        <div
                                          className="timeline-panel p-1 m-2"
                                          onClick={(event) => {
                                            showTasks(event, task);
                                          }}
                                        >
                                          <div className="media me-2 media-danger">
                                            {task?.task_owner ? (
                                              task?.task_owner?.first_name[0]?.toUpperCase() +
                                              "" +
                                              task?.task_owner?.last_name[0]?.toUpperCase()
                                            ) : task?.org_owner ? (
                                              <img
                                                src={task?.org_image || org1}
                                                className="img-fluidp img-thumbnailp p-1 m-0"
                                                alt="task-owner"
                                                title="task-owner"
                                                style={{
                                                  height: "50px",
                                                  width: "70px",
                                                }}
                                              ></img>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <Col>
                                            <Row className="text-start">
                                              <h5 className="">{task?.name}</h5>
                                            </Row>
                                            <Row className="text-start">
                                              <span className="p">
                                                {changedDateFormats(
                                                  task?.created_at
                                                )}{" "}
                                                at{" "}
                                                {getTimeZone(task?.created_at)}
                                              </span>
                                            </Row>
                                          </Col>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )
                              ) : (
                                <div
                                  className="timeline bg-white mb-2"
                                  style={{ borderRadius: "10px" }}
                                >
                                  <div className="timeline-panel p-1">
                                    <h5
                                      className="filterText fs-14 mt-3"
                                      style={{
                                        justifyContent: "center",
                                        flex: 1,
                                      }}
                                    >
                                      Not found
                                    </h5>
                                  </div>
                                </div>
                              )}
                            </Row>
                            {selectedSearchResult?.tasks?.length >= 5 &&
                              !loader && (
                                <Link
                                  className="text-danger float-end me-3 my-2"
                                  to=""
                                  style={{ textAlign: "end" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setLoadMore(!LoadMore);
                                    setActiveTab("tab2");
                                    setSelectedSearchresult([]);
                                    setType("task");
                                  }}
                                >
                                  <b>
                                    {" "}
                                    <u>Load more</u>...
                                  </b>
                                </Link>
                              )}
                          </Row>
                          <Row className="ms-3 mt-2">
                            Objectives :
                            <Row>
                              {selectedSearchResult?.goals?.length > 0 ? (
                                selectedSearchResult?.goals?.map(
                                  (goal, index) => (
                                    <>
                                      <div
                                        className="timeline m-1 p-0"
                                        style={{
                                          borderRadius: "10px",
                                          cursor: "pointer",
                                          backgroundColor: "#ffffff",
                                        }}
                                        key={index}
                                      >
                                        <div
                                          className="timeline-panel p-1 m-2 "
                                          onClick={(event) => {
                                            showGoals(event, goal);
                                          }}
                                        >
                                          <div className="media me-2 media-danger">
                                            {goal?.goal_owner ? (
                                              goal?.goal_owner?.first_name[0]?.toUpperCase() +
                                              "" +
                                              goal?.goal_owner?.last_name[0]?.toUpperCase()
                                            ) : goal?.first_name ? (
                                              <img
                                                src={
                                                  goal?.profile_picture ||
                                                  profilePicture
                                                }
                                                className="img-fluidp img-thumbnailp p-1 m-0"
                                                alt="goal-owner"
                                                title="goal-owner"
                                                style={{
                                                  height: "50px",
                                                  width: "70px",
                                                }}
                                              ></img>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <Col>
                                            <Row className="text-start">
                                              <h5 className="fs-14">
                                                {goal?.name}
                                              </h5>
                                            </Row>
                                            <Row className="text-start">
                                              <span className="p">
                                                {changedDateFormats(
                                                  goal?.created_at
                                                )}{" "}
                                                at{" "}
                                                {getTimeZone(goal?.created_at)}
                                              </span>
                                            </Row>
                                          </Col>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )
                              ) : (
                                <div
                                  className="timeline bg-white mb-2"
                                  style={{ borderRadius: "10px" }}
                                >
                                  <div className="timeline-panel p-1">
                                    <h5
                                      className="filterText fs-14 mt-3"
                                      style={{
                                        justifyContent: "center",
                                        flex: 1,
                                      }}
                                    >
                                      Not found
                                    </h5>
                                  </div>
                                </div>
                              )}
                            </Row>
                            {selectedSearchResult?.goals?.length >= 5 &&
                              !loader &&
                              LoadMoreButton && (
                                <Link
                                  className="text-danger float-end me-3 my-2"
                                  to=""
                                  style={{ textAlign: "end" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setLoadMore(!LoadMore);
                                    setActiveTab("tab3");
                                    setSelectedSearchresult([]);
                                    setType("goal");
                                  }}
                                >
                                  <b>
                                    {" "}
                                    <u>Load more</u>...
                                  </b>
                                </Link>
                              )}
                          </Row>
                          <Row className="ms-3 mt-2">
                            Message :
                            <Row>
                              {selectedSearchResult &&
                              selectedSearchResult?.conversations?.length >
                                0 ? (
                                selectedSearchResult?.conversations?.map(
                                  (goal, index) => (
                                    <>
                                      <div
                                        className="timeline m-1 p-0"
                                        style={{
                                          borderRadius: "10px",
                                          cursor: "pointer",
                                          backgroundColor: "#ffffff",
                                        }}
                                        onClick={(e) => {
                                          showMessages(e, goal);
                                        }}
                                        key={index}
                                      >
                                        <div className="timeline-panel p-1 m-2">
                                          <div className="d-flex m-0 p-0">
                                            <img
                                              src={
                                                goal?.thread?.thread_image ||
                                                groupImage
                                              }
                                              className="img-fluidp img-thumbnailp p-1 m-0"
                                              alt="timeline-pannel"
                                              title="timeline-pannel"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                              }}
                                            ></img>
                                          </div>
                                          <Row className="text-start m-0 p-0 w-100">
                                            <Col className="col-sm-7">
                                              <Row>
                                                <span className="mb-1">
                                                  <h5>
                                                    {goal?.text
                                                      ? "Thread - " +
                                                        goal?.thread
                                                          ?.thread_topic
                                                      : goal?.name
                                                      ? goal?.name
                                                      : goal?.first_name +
                                                        " " +
                                                        goal?.last_name}
                                                  </h5>
                                                </span>
                                                <small>
                                                  {!goal?.name ? (
                                                    <>
                                                      <Row className="text-start">
                                                        {!goal?.auth_user ? (
                                                          <span className="me-2">
                                                            <h5
                                                              style={{
                                                                display:
                                                                  "inline",
                                                              }}
                                                            >
                                                              {" "}
                                                              {goal?.sender
                                                                ?.id ===
                                                              userInfoDetails
                                                                ?.data?.id
                                                                ? "You" + " : "
                                                                : goal?.sender
                                                                    ?.first_name[0] +
                                                                  goal?.sender
                                                                    ?.last_name[0] +
                                                                  " : "}
                                                            </h5>
                                                            <span className="text-wrap">
                                                              {goal?.text}
                                                            </span>
                                                          </span>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </Row>
                                                    </>
                                                  ) : (
                                                    <Row className="">
                                                      <span className="p">
                                                        {changedDateFormats(
                                                          goal?.created_at
                                                        )}{" "}
                                                        at{" "}
                                                        {getTimeZone(
                                                          goal?.created_at
                                                        )}
                                                      </span>
                                                    </Row>
                                                  )}
                                                </small>
                                              </Row>
                                              <Row>
                                                {goal?.text ? (
                                                  <span className="p">
                                                    {changedDateFormats(
                                                      goal?.created_at
                                                    )}{" "}
                                                    at{" "}
                                                    {getTimeZone(
                                                      goal?.created_at
                                                    )}
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </Row>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )
                              ) : (
                                <div
                                  className="timeline bg-white mb-2"
                                  style={{ borderRadius: "10px" }}
                                >
                                  <div className="timeline-panel p-1">
                                    <h5
                                      className="filterText fs-14 mt-3"
                                      style={{
                                        justifyContent: "center",
                                        flex: 1,
                                      }}
                                    >
                                      Not found
                                    </h5>
                                  </div>
                                </div>
                              )}
                            </Row>
                            {selectedSearchResult?.conversations?.length >= 5 &&
                              !loader && (
                                <Link
                                  className="text-danger float-end me-3 my-2"
                                  to=""
                                  style={{ textAlign: "end" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setLoadMore(!LoadMore);
                                    setActiveTab("tab4");
                                    setSelectedSearchresult([]);
                                    setType("message");
                                  }}
                                >
                                  <b>
                                    {" "}
                                    <u>Load more</u>...
                                  </b>
                                </Link>
                              )}
                          </Row>
                          <Row className="ms-3 mt-2">
                            People :
                            <Row>
                              {selectedSearchResult?.people?.length > 0 ? (
                                selectedSearchResult?.people?.map(
                                  (people, index) => (
                                    <>
                                      <div
                                        className="timeline bg-white m-1 p-0"
                                        style={{
                                          borderRadius: "10px",
                                          cursor: "pointer",
                                          backgroundColor: "#ffffff",
                                        }}
                                        key={index}
                                      >
                                        <div
                                          className="timeline-panel p-1 m-2"
                                          onClick={(event) => {
                                            showUsers(event, people);
                                          }}
                                        >
                                          <div className="media me-2 media-danger">
                                            {people ? (
                                              people?.first_name[0]?.toUpperCase() +
                                              "" +
                                              people?.last_name[0]?.toUpperCase()
                                            ) : people?.profile_picture ? (
                                              <img
                                                src={
                                                  people?.profile_picture ||
                                                  org1
                                                }
                                                className="img-fluidp img-thumbnailp p-1 m-0"
                                                alt="profile-pic"
                                                title="profile-pic"
                                                style={{
                                                  height: "50px",
                                                  width: "70px",
                                                }}
                                              ></img>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <Row className="text-start">
                                            <h5 className="fs-14">
                                              {people?.first_name +
                                                " " +
                                                people?.last_name}
                                            </h5>
                                          </Row>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )
                              ) : (
                                <div
                                  className="timeline bg-white mb-2"
                                  style={{ borderRadius: "10px" }}
                                >
                                  <div className="timeline-panel p-1">
                                    <h5
                                      className="filterText fs-14 mt-3"
                                      style={{
                                        justifyContent: "center",
                                        flex: 1,
                                      }}
                                    >
                                      Not found
                                    </h5>
                                  </div>
                                </div>
                              )}
                            </Row>
                            {selectedSearchResult?.people?.length >= 5 &&
                              !loader && (
                                <Link
                                  className="text-danger float-end me-3 my-2"
                                  to=""
                                  style={{ textAlign: "end" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setLoadMore(!LoadMore);
                                    setActiveTab("tab6");
                                    setSelectedSearchresult([]);
                                    setType("people");
                                  }}
                                >
                                  <b>
                                    {" "}
                                    <u>Load more</u>...
                                  </b>
                                </Link>
                              )}
                          </Row>
                          <Row className="ms-3 mt-2">
                            Team :
                            <Row>
                              {selectedSearchResult?.team?.length > 0 ? (
                                selectedSearchResult?.team?.map(
                                  (team, index) => (
                                    <>
                                      <div
                                        className="timeline m-1 p-0"
                                        style={{
                                          borderRadius: "10px",
                                          cursor: "pointer",
                                          backgroundColor: "#ffffff",
                                        }}
                                        key={index}
                                      >
                                        <div
                                          className="timeline-panel p-1 m-2"
                                          onClick={(event) => {
                                            showTeams(event, team);
                                          }}
                                        >
                                          <div className="media me-2 media-danger">
                                            {team?.name ? (
                                              <img
                                                src={
                                                  team?.team_image || groupImage
                                                }
                                                className="img-fluidp img-thumbnailp p-1 m-0"
                                                alt="timeline-panel"
                                                title="timeline-panel"
                                                style={{
                                                  height: "50px",
                                                  width: "70px",
                                                }}
                                              ></img>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <Col>
                                            <Row className="text-start">
                                              <h5 className="fs-14">
                                                {team?.name}
                                              </h5>
                                            </Row>
                                            <Row className="text-start">
                                              <span className="p">
                                                {changedDateFormats(
                                                  team?.created_at
                                                )}{" "}
                                                at{" "}
                                                {getTimeZone(team?.created_at)}
                                              </span>
                                            </Row>
                                          </Col>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )
                              ) : (
                                <div
                                  className="timeline bg-white mb-2"
                                  style={{ borderRadius: "10px" }}
                                >
                                  <div className="timeline-panel p-1">
                                    <h6
                                      className="filterText fs-14 mt-3"
                                      style={{
                                        justifyContent: "center",
                                        flex: 1,
                                      }}
                                    >
                                      Not found
                                    </h6>
                                  </div>
                                </div>
                              )}
                            </Row>
                            {selectedSearchResult?.team?.length >= 5 &&
                              !loader && (
                                <Link
                                  className="text-danger float-end me-3 my-2"
                                  to=""
                                  style={{ textAlign: "end" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setLoadMore(!LoadMore);
                                    setActiveTab("tab7");
                                    setSelectedSearchresult([]);
                                    setType("team");
                                  }}
                                >
                                  <b>
                                    {" "}
                                    <u>Load more</u>...
                                  </b>
                                </Link>
                              )}
                          </Row>
                          <Row className="ms-3 mt-2">
                            Organization :
                            <Row>
                              {selectedSearchResult?.organization?.length >
                              0 ? (
                                selectedSearchResult?.organization?.map(
                                  (organization, index) => (
                                    <>
                                      <div
                                        className="timeline bg-white m-1 p-0"
                                        style={{
                                          borderRadius: "10px",
                                          cursor: "pointer",
                                          backgroundColor: "#ffffff",
                                        }}
                                        key={index}
                                      >
                                        <div
                                          className="timeline-panel p-1 m-2"
                                          onClick={(event) => {
                                            showOrganizations(
                                              event,
                                              organization
                                            );
                                          }}
                                        >
                                          <div className="media me-2 media-danger">
                                            {organization?.name ? (
                                              <img
                                                src={
                                                  organization?.org_image ||
                                                  groupImage
                                                }
                                                className="img-fluidp img-thumbnailp p-1 m-0"
                                                alt="org-search"
                                                title="org-search"
                                                style={{
                                                  height: "50px",
                                                  width: "70px",
                                                }}
                                              ></img>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <Col>
                                            <Row className="text-start">
                                              <h5 className="fs-14">
                                                {organization?.name}
                                              </h5>
                                            </Row>
                                            <Row className="text-start">
                                              <span className="p">
                                                {changedDateFormats(
                                                  organization?.created_at
                                                )}{" "}
                                                at{" "}
                                                {getTimeZone(
                                                  organization?.created_at
                                                )}
                                              </span>
                                            </Row>
                                          </Col>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )
                              ) : (
                                <div
                                  className="timeline bg-white mb-2"
                                  style={{ borderRadius: "10px" }}
                                >
                                  <div className="timeline-panel p-1">
                                    <h5
                                      className="filterText fs-14 mt-3"
                                      style={{
                                        justifyContent: "center",
                                        flex: 1,
                                      }}
                                    >
                                      Not found
                                    </h5>
                                  </div>
                                </div>
                              )}
                            </Row>
                            {selectedSearchResult?.organization?.length >= 5 &&
                              !loader && (
                                <Link
                                  className="text-danger float-end me-3 my-2"
                                  to=""
                                  style={{ textAlign: "end" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setLoadMore(!LoadMore);
                                    setActiveTab("tab8");
                                    setSelectedSearchresult([]);
                                    setType("organization");
                                  }}
                                >
                                  <b>
                                    {" "}
                                    <u>Load more</u>...
                                  </b>
                                </Link>
                              )}
                          </Row>
                        </>
                      ) : activeTab === "tab5" ? (
                        <>
                          {selectedSearchResult?.length !== 0 &&
                            selectedSearchResult !== null &&
                            selectedSearchResult?.map((goal, index) => (
                              <div
                                className="timeline bg-white"
                                style={{ borderRadius: "10px" }}
                                key={index}
                              >
                                <div className="timeline-panel p-2">
                                  <div className="media me-2 media-danger bg-white border-0">
                                    {
                                      <a
                                        className="text-center py-1 b-0"
                                        href={goal?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                        title="Click to download attachment "
                                      >
                                        <span style={{ color: "red" }}>
                                          <i
                                            class="bi bi-file-earmark-arrow-down h1"
                                            style={{ color: "#a97dbd" }}
                                          ></i>
                                        </span>
                                      </a>
                                    }
                                  </div>
                                  <div className="media-body m-0 text-start">
                                    <div>
                                      <div className="col-sm-9">
                                        <h5 className="wrap search-attach-name">
                                          {
                                            getNameAndTypes(goal?.attachments)
                                              ?.name
                                          }
                                        </h5>
                                      </div>
                                      <div className="col text-end">
                                        {goal?.attachments ? (
                                          <span className="text-end">
                                            {changedDateFormats(
                                              goal?.timestamp
                                            )}{" "}
                                            at {getTimeZone(goal?.timestamp)}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <Row>
                                      {goal?.goal ? (
                                        <Col>
                                          <h5 className="">
                                            Goal name :
                                            <span className="p">
                                              {"  " + goal?.goal?.name}
                                            </span>
                                          </h5>
                                        </Col>
                                      ) : goal?.conversation ? (
                                        <Col>
                                          <h5 className="">
                                            Thread name :
                                            <span className="p">
                                              {"  " +
                                                goal?.conversation?.thread
                                                  ?.thread_topic}
                                            </span>
                                          </h5>
                                        </Col>
                                      ) : (
                                        <Col>
                                          <h5 className="">
                                            Task name :
                                            <span className="p">
                                              {" " + goal?.task?.name}
                                            </span>
                                          </h5>
                                        </Col>
                                      )}
                                      <Col>
                                        <span className="float-end me-4">
                                          {goal?.goal
                                            ? goal?.goal?.goal_owner
                                                ?.first_name +
                                              " " +
                                              goal?.goal?.goal_owner?.last_name
                                            : goal?.task
                                            ? goal?.task?.task_owner
                                                ?.first_name +
                                              " " +
                                              goal?.task?.task_owner?.last_name
                                            : goal?.conversation?.sender
                                                ?.first_name +
                                              " " +
                                              goal?.conversation?.sender
                                                ?.last_name}
                                        </span>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      ) : (
                        Array.isArray(selectedSearchResult) &&
                        selectedSearchResult?.length !== 0 &&
                        selectedSearchResult?.map((goal, index) => (
                          <>
                            <div
                              className="timeline m-1 p-0"
                              style={{
                                borderRadius: "10px",
                                cursor: "pointer",
                                backgroundColor: "#ffffff",
                              }}
                              key={index}
                            >
                              <div
                                className="timeline-panel p-1 m-2"
                                onClick={(event) => {
                                  setFunction(event, goal);
                                }}
                              >
                                <div className="media me-2 media-danger">
                                  {goal?.goal_owner ? (
                                    goal?.goal_owner?.first_name[0]?.toUpperCase() +
                                    "" +
                                    goal?.goal_owner?.last_name[0]?.toUpperCase()
                                  ) : goal?.first_name ? (
                                    <img
                                      src={
                                        goal?.profile_picture || profilePicture
                                      }
                                      className="img-fluidp img-thumbnailp p-1 m-0"
                                      alt="goal-fn"
                                      title="goal-fn"
                                      style={{ height: "70px", width: "70px" }}
                                    ></img>
                                  ) : goal?.task_owner ? (
                                    goal?.task_owner?.first_name[0]?.toUpperCase() +
                                    "" +
                                    goal?.task_owner?.last_name[0]?.toUpperCase()
                                  ) : goal?.org_owner ? (
                                    <img
                                      src={goal?.org_image || org1}
                                      className="img-fluidp img-thumbnailp p-1 m-0"
                                      alt="goal-org-img"
                                      title="goal-org-img"
                                      style={{ height: "50px", width: "70px" }}
                                    ></img>
                                  ) : goal?.team_manager ? (
                                    <img
                                      src={goal?.team_image || groupImage}
                                      className="img-fluidp img-thumbnailp p-1 m-0"
                                      alt="team-img"
                                      title="team-img"
                                      style={{ height: "50px", width: "70px" }}
                                    ></img>
                                  ) : goal?.thread ? (
                                    <img
                                      src={
                                        goal?.thread?.thread_image || groupImage
                                      }
                                      className="img-fluidp img-thumbnailp p-1 m-0"
                                      alt="thread"
                                      title="thread"
                                      style={{ height: "50px", width: "70px" }}
                                    ></img>
                                  ) : (
                                    "NA"
                                  )}
                                </div>
                                <div
                                  className="media-body m-0 ms-1"
                                  onClick={(e) => {
                                    showMessages(e, goal);
                                  }}
                                >
                                  <Row className="text-start">
                                    <Col>
                                      <span className="mb-1">
                                        <h5>
                                          {goal?.text
                                            ? "Thread - " +
                                              goal?.thread?.thread_topic
                                            : goal?.name
                                            ? goal?.name
                                            : goal?.first_name +
                                              " " +
                                              goal?.last_name}
                                        </h5>
                                      </span>
                                    </Col>
                                    <Col style={{ maxWidth: "240px" }}>
                                      {goal?.text ? (
                                        <span className="p">
                                          {changedDateFormats(goal?.created_at)}{" "}
                                          at {getTimeZone(goal?.created_at)}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                  <Row></Row>
                                  <small className="d-block">
                                    {!goal?.name ? (
                                      <>
                                        <Row className="text-start">
                                          <span className="fs-14">
                                            {!goal?.auth_user ? (
                                              <b className="me-2">
                                                {goal?.sender?.id ===
                                                userInfoDetails?.data?.id
                                                  ? "You" + " : "
                                                  : goal?.sender
                                                      ?.first_name[0] +
                                                    goal?.sender?.last_name[0] +
                                                    " : "}
                                              </b>
                                            ) : (
                                              ""
                                            )}
                                            <span className="text-wrap">
                                              {goal?.text}
                                            </span>
                                          </span>
                                        </Row>
                                      </>
                                    ) : (
                                      <Row className="text-start">
                                        <span className="fs-14">
                                          {changedDateFormats(goal?.created_at)}{" "}
                                          at {getTimeZone(goal?.created_at)}
                                        </span>
                                      </Row>
                                    )}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      )}
                      <div
                        className="ps__rail-x"
                        style={{ left: 0, bottom: 0 }}
                      >
                        <div
                          className="ps__thumb-x"
                          tabIndex={0}
                          style={{ left: 0, width: 0 }}
                        />
                      </div>
                      <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                        <div
                          className="ps__thumb-y"
                          tabIndex={0}
                          style={{ top: 0, height: 0 }}
                        />
                      </div>
                    </>
                  ) : (
                    <Row className="text-center">
                      <span className="fs-16 fontW700">
                        No results in this space
                      </span>
                      <span className="fs-12">
                        Try a different search term or search all spaces and
                        direct messages
                      </span>
                    </Row>
                  )}
                </PerfectScrollbar>
              </>
            ) : (
              ""
            )}
            {selectedSearchResult &&
              selectedSearchResult?.length >= 5 &&
              !loader && (
                <Link
                  className="text-danger float-end me-3 my-2"
                  to=""
                  onClick={(e) => {
                    e.preventDefault();
                    setLoadMore(!LoadMore);
                  }}
                >
                  <b>
                    {" "}
                    <u>Load more</u>...
                  </b>
                </Link>
              )}
          </div>
        }
      </Modal>
    </>
  );
};

export default memo(SearchModal);
